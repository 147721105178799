import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// الموارد اللغوية
const resources = {
  en: {
    translation: require("./messages/en.json"),
  },
  ar: {
    translation: require("./messages/ar.json"),
  },
};

// القيمة الافتراضية
const defaultLanguage = localStorage.getItem("language") || "ar"; // اللغة الافتراضية هي الإنجليزية;

i18n
  .use(initReactI18next) // استخدام i18next مع React
  .init({
    resources,
    lng: defaultLanguage, // استخدام اللغة الافتراضية
    interpolation: {
      escapeValue: false, // لا حاجة للهروب من القيم
    },
    // استخدام اللغة الافتراضية
    fallbackLng: "ar",
  });

export default i18n;
