import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "@fontsource/almarai/400.css";
import "@fontsource/almarai/700.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { LanguageProvider, useLanguage } from "./components/LanguageContext";
import Loadable from "./components/Loadable";

const Services = Loadable(
  React.lazy(() => import("./pages/Services/Services"))
);
const Terms = Loadable(React.lazy(() => import("./pages/Terms/Terms")));
const Privacy = Loadable(React.lazy(() => import("./pages/Privacy/Privacy")));
const Blogs = Loadable(React.lazy(() => import("./pages/Blogs/Blogs")));
const Desktopdevelopment = Loadable(
  React.lazy(() =>
    import("./pages/Services/desktop-development/desktop-development")
  )
);
const ElectronicSystems = Loadable(
  React.lazy(() =>
    import("./pages/Services/electronic-Systems/electronic-Systems")
  )
);
const Itsupport = Loadable(
  React.lazy(() => import("./pages/Services/it-support/page"))
);
const Mobileappdevelopment = Loadable(
  React.lazy(() => import("./pages/Services/mobile-app-development/page"))
);
const Uiuxdesign = Loadable(
  React.lazy(() => import("./pages/Services/ui-ux-design/page"))
);
const WebAppHosting = Loadable(
  React.lazy(() => import("./pages/Services/web-app-hosting/page"))
);
const Webdevelopment = Loadable(
  React.lazy(() => import("./pages/Services/web-development/page"))
);
const Contact = Loadable(React.lazy(() => import("./pages/Contact/Contact")));
const Home = Loadable(React.lazy(() => import("./pages/Home/Home")));
const Notfound = Loadable(
  React.lazy(() => import("./pages/Notfound/Notfound"))
);

const App = () => {
  return (
    <LanguageProvider>
      <Main />
    </LanguageProvider>
  );
};

const Main = () => {
  const { locale, langDir } = useLanguage();
  useEffect(() => {
    Aos.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  });

  return (
    <div
      lang={locale}
      dir={langDir}
      className={`[&:lang(en)]:font-inter [&:lang(ar)]:font-almarai font-inter tracking-tight text-gray-900 antialiased`}
    >
      <div className="flex min-h-screen flex-col overflow-hidden supports-[overflow:clip]:overflow-clip">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<Notfound />} />
          <Route path="/our-services" element={<Services />} />
          <Route
            path="/our-services/desktop-development"
            element={<Desktopdevelopment />}
          />
          <Route
            path="/our-services/electronic-Systems"
            element={<ElectronicSystems />}
          />
          <Route path="/our-services/it-support" element={<Itsupport />} />
          <Route
            path="/our-services/mobile-app-development"
            element={<Mobileappdevelopment />}
          />
          <Route path="/our-services/ui-ux-design" element={<Uiuxdesign />} />
          <Route
            path="/our-services/web-app-hosting"
            element={<WebAppHosting />}
          />
          <Route
            path="/our-services/web-development"
            element={<Webdevelopment />}
          />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/blog" element={<Blogs />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
