import i18next from "i18next";
import React, { createContext, useContext, useState, useEffect } from "react";
import rtlDetect from "rtl-detect";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [langDir, setLangDir] = useState("rtl");
  const [locale, setLocale] = useState(() => {
    return localStorage.getItem("language") || "ar";
  }); // القيمة الافتراضية

  useEffect(() => {
    const newLangDir = rtlDetect.getLangDir(locale);
    setLangDir(newLangDir);
  }, [locale]);

  const changeLanguage = (newLocale) => {
    setLocale(newLocale);
    i18next.changeLanguage(newLocale);
    localStorage.setItem("language", newLocale);
  };

  return (
    <LanguageContext.Provider value={{ langDir, locale, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};

// LanguageContext.js
